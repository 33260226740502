import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import MsTeams from "./MsTeams";
import useAuth from "../../hooks/useAuth";
import useSwal from "../../hooks/useSwal";
import useLocale from "../../hooks/useLocale";
import { Dictionary } from "../../types/Dictionary";
import { AccessPermissionEnum } from "../../enums";
import UnauthorizedAccess from "../error/UnauthorizedAccess";
import LoadingOverlay from "../../components/LoadingOverlay";
import customerService from "../../services/customer.service";
import { PlatformDetail } from "../../types/customer";
import useMasterData from "../../hooks/useMasterData";

const CustomerPlatform: React.FC = () => {
  const masterData = useMasterData();
  const auth = useAuth();
  const Swal = useSwal();
  const localeCtx = useLocale();
  const [selected, setSelected] = useState<number>(0);
  const [pageFreezeLoader, setPageFreezeLoader] = useState<boolean>(false);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["SystemSettings"]);
  const [customerPlatformDetails, setCustomerPlatformDetails] = useState<PlatformDetail[]>([]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "CustomerPlatformDetail"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    getCustomerPlatformDetails();
  }, []);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "CustomerPlatformDetail"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "CustomerPlatformDetail"
        ]
      );
      localeCtx?.setPreviousAppLocale("CustomerPlatformDetail");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const getCustomerPlatformDetails = async () => {
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      try {
        setPageFreezeLoader(true);
        const platformDetails = await customerService.getCustomerPlatformDetail(
          customerId
        );
        setCustomerPlatformDetails(platformDetails);
      } catch (err) {
        if (err instanceof AxiosError) {
          console.log(err);
          setCustomerPlatformDetails([]);
        }
      } finally {
        setPageFreezeLoader(false);
      }
    }
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageAccount) ? (
        <UnauthorizedAccess />
      ) : (
        <>
          {pageFreezeLoader && (
            <LoadingOverlay
              customStyle={{
                position: "fixed",
                marginTop: "55px",
                zIndex: "999999",
              }}
              themeColor={"light"}
              size={"medium"}
              loadingText={"Please wait..."}
            />
          )}
          <div className="m-b-20">
            <div className="row tabLeftView">
              <div className="col-md-12">
                <div className="card cardEffect">
                  <div className="card-header bg-white d-flex justify-content-between">
                    <span className="detailHeader d-flex align-items-center">
                      <span className="fs-16 font-weight-semi p-r-20">
                        {`${
                          translationsLoading
                            ? "Customer Platform Details"
                            : fetchLabelKeyTranslation(
                                "CustomerPlatformDetailsTitle",
                                "Customer Platform Details"
                              )
                        }`}
                      </span>
                    </span>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="settingData k-custom-tab p-t-20 p-r-15 p-l-20 p-b-20 systemSet">
                          <TabStrip
                            tabPosition={"left"}
                            selected={selected}
                            onSelect={handleSelect}
                          >
                            {customerPlatformDetails !== undefined && customerPlatformDetails !== null &&
                              customerPlatformDetails.map((platformDetail) => {
                                return (
                                  <TabStripTab title={masterData?.data?.platforms.find(p => p.name === platformDetail.platform)?.localizationValue}>
                                    <div className="m-b-10">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="trk-container customerViewBox">
                                            <div className="trk-inr p-b-30">
                                              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                                                <span className="text-primary">{masterData?.data?.platforms.find(p => p.name === platformDetail.platform)?.localizationValue}</span>
                                              </div>
                                              <div>
                                              {platformDetail.platform === "Broadworks" && 
                                                <>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Enterprise Id"
                                                          : fetchLabelKeyTranslation("EnterpriseId", "Enterprise Id")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.enterpriseId}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Group Id"
                                                          : fetchLabelKeyTranslation("GroupId", "Group Id")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.groupId}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Directory User"
                                                          : fetchLabelKeyTranslation("DirectoryUser", "Directory User")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.directoryUser}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Xsi User Id"
                                                          : fetchLabelKeyTranslation("XsiUserId", "Xsi User Id")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.xsiUsername}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Xsi Password"
                                                          : fetchLabelKeyTranslation("XsiPassword", "Xsi Password")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.xsiPassword}</div>
                                                    </div>
                                                  </div>
                                                </>}
                                                {platformDetail.platform === "Telepo" && 
                                                <>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Organisation Id"
                                                          : fetchLabelKeyTranslation("OrganisationId", "Organisation Id")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.organizationId}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Organisation Name"
                                                          : fetchLabelKeyTranslation("OrganisationName", "Organisation Name")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.organizationName}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Organisation Domain"
                                                          : fetchLabelKeyTranslation("OrganisationDomain", "Organisation Domain")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.organizationDomain}</div>
                                                    </div>
                                                  </div>
                                                </>}
                                                {platformDetail.platform === "SBC-MsTeams" && 
                                                <>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Sip Customer Ref."
                                                          : fetchLabelKeyTranslation("SipCustomerRef", "Sip Customer Ref.")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.sipCustomerReference}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Tenant Id"
                                                          : fetchLabelKeyTranslation("TenantId", "Tenant Id")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.sbcMsTeamsTenantGuid}</div>
                                                    </div>
                                                  </div>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Admin Email"
                                                          : fetchLabelKeyTranslation("AdminEmail", "Admin Email")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.msTeamsAdminEmail}</div>
                                                    </div>
                                                  </div>
                                                </>}
                                                {platformDetail.platform === "SBC-Others" && 
                                                <>
                                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                                    <div className="trkCol">
                                                      <div className="trkCol-h font-weight-semi">
                                                        {`${translationsLoading ? "Sip Customer Ref."
                                                          : fetchLabelKeyTranslation("SipCustomerRef", "Sip Customer Ref.")}`}
                                                      </div>
                                                      <div className="trkCol-dot">:</div>
                                                      <div className="trkCol-p">{platformDetail.customerDetail.sbcOthersSipCustomerReference}</div>
                                                    </div>
                                                  </div>
                                                </>}
                                                {platformDetail.platform === "MsTeams" && 
                                                <>
                                                  <MsTeams platformDetail={platformDetail}/>
                                                </>}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </TabStripTab>
                                )
                              })
                            }
                          </TabStrip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerPlatform;
